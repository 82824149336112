import clsx from 'clsx'
import { motion } from 'framer-motion'
import { t } from 'i18next'
import { useEffect, useRef, useState, VFC } from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { Helmet } from 'react-helmet-async'
import { Trans } from 'react-i18next'

import { useClientFaqGetFaqListQuery } from '~/app/api'
import { CButton } from '~/components/common/cButton/CButton'
import { CLinkButton } from '~/components/common/cLinkButton/CLinkButton'
import { CFaqListItem } from '~/components/unique/faq/CFaqListItem'

import CMessage from '../../components/common/cMessage/CMessage'
import CDownloadForm from '../../components/unique/form/CDownloadForm'

export type LPViewProps = {} & LPProps

export const LPView: VFC<LPViewProps> = () => {
    const [interview1Visible, setInterview1Visible] = useState(false)
    const [interview2Visible, setInterview2Visible] = useState(false)
    const [interview3Visible, setInterview3Visible] = useState(false)
    const [interview4Visible, setInterview4Visible] = useState(false)
    const [interview5Visible, setInterview5Visible] = useState(false)
    // FAQ一覧を取得
    const { data: faqList, isLoading: isLoadingFaqList } = useClientFaqGetFaqListQuery({ home: true })

    const voices = [
        {
            num: '01',
            content: t('LP.物件紹介メールの作成に時間がかかる'),
            translate: ['lg:-translate-x-80'],
            angle: 'left',
        },
        {
            num: '02',
            content: t('LP.紹介先の反応がわからない'),
            translate: ['lg:translate-x-60', 'lg:-translate-y-16'],
            angle: 'right',
        },
        {
            num: '03',
            content: t('LP.紹介漏れ・誰にどの物件を紹介したかわからない'),
            translate: ['lg:-translate-x-32', 'lg:-translate-y-12'],
            angle: 'left',
        },
        {
            num: '04',
            content: t('LP.社内の情報管理ルールが守られない'),
            translate: ['lg:translate-x-72', 'lg:-translate-y-24'],
            angle: 'right',
        },
        {
            num: '05',
            content: t('LP.新規の物件情報が集められない'),
            translate: ['lg:-translate-x-60', 'lg:-translate-y-20'],
            angle: 'left',
        },
        {
            num: '06',
            content: t('LP.自社顧客ニーズやどの物件を紹介したらいいかわからない'),
            translate: ['lg:translate-x-28', 'lg:-translate-y-20'],
            angle: 'right',
        },
    ]
    const encounterSupport = [
        {
            img: '/src/assets/image/page/lp/support-function/buy-market.svg',
            title: t('LP.マーケット（購入）'),
            description: t('LP.非公開物件情報に<br />簡単にアプローチが可能 ※'),
            annotation: t('LP.※ 詳細情報の開示可否は相手企業が判断'),
        },
        {
            img: '/src/assets/image/page/lp/support-function/sale-market.svg',
            title: t('LP.マーケット（売却）'),
            description: t('LP.売却希望の物件を非公開で<br />掲載することが可能 ※'),
            annotation: t('LP.※ 詳細情報の開示可否は自社が判断'),
        },
        {
            img: '/src/assets/image/page/lp/support-function/member-matching.svg',
            title: t('LP.キマール会員マッチング'),
            description: t('LP.自社物件とマッチングしそうな<br />社外のキマール会員をAIが提案'),
            annotation: '',
        },
    ]
    const contractSupport = [
        {
            img: '/src/assets/image/page/lp/support-function/Introducing-property.svg',
            title: t('LP.物件管理／顧客管理'),
            description: t('LP.登録作業を極力省人化し、<br />必要な情報を全て一元管理'),
            annotation: '',
        },
        {
            img: '/src/assets/image/page/lp/support-function/manage-properties-customers.svg',
            title: t('LP.物件紹介'),
            description: t('LP.紹介メールのカスタマイズと<br />一斉配信が簡単に可能'),
            annotation: '',
        },
        {
            img: '/src/assets/image/page/lp/support-function/customer-matching.svg',
            title: t('LP.自社顧客マッチング'),
            description: t('LP.自社物件とマッチングしそうな<br />自社顧客をAIが提案'),
            annotation: '',
        },
        {
            img: '/src/assets/image/page/lp/support-function/document-preparation.svg',
            title: t('LP.概要書作成機能'),
            description: t('LP.物件概要書が<br />簡単に作成可能'),
            annotation: '',
        },
    ]
    const companies = [
        {
            src: '/src/assets/image/company_logo/mb.png',
            alt: '丸紅都市開発株式会社',
            half: false,
        },
        {
            src: '/src/assets/image/company_logo/ttf.png',
            alt: '東京建物不動産株式会社',
            half: false,
        },
        {
            src: '/src/assets/image/company_logo/tk.png',
            alt: '大東建託株式会社',
            half: false,
        },
        {
            src: '/src/assets/image/company_logo/taisei-yuraku.png',
            alt: '大成有楽不動産販売株式会社',
            half: false,
        },
        {
            src: '/src/assets/image/company_logo/logo_jr_west.png',
            alt: 'JR西日本プロパティーズ株式会社',
            half: false,
        },
        {
            src: '/src/assets/image/company_logo/keikyu.png',
            alt: '京急不動産株式会社',
            half: false,
        },
        {
            src: '/src/assets/image/company_logo/kintetsu.png',
            alt: '近鉄不動産株式会社',
            half: false,
        },
        {
            src: '/src/assets/image/company_logo/nittetsu.png',
            alt: '日鉄興和不動産株式会社',
            half: false,
        },
        {
            src: '/src/assets/image/company_logo/stgrande.png',
            alt: '株式会社セントグランデW',
            half: false,
        },
        {
            src: '/src/assets/image/company_logo/logo_list.png',
            alt: 'リストインターナショナルリアルティ株式会社',
            half: false,
        },
        {
            src: '/src/assets/image/company_logo/sunfrontier.png',
            alt: 'サンフロンティア不動産株式会社',
            half: false,
        },
        {
            src: '/src/assets/image/company_logo/logo_JREX.png',
            alt: 'ジェイレックス・コーポレーション株式会社',
            half: true,
        },
        {
            src: '/src/assets/image/company_logo/aeonhousing.png',
            alt: 'イオンハウジング',
            half: false,
        },
        {
            src: '/src/assets/image/company_logo/jogjog.png',
            alt: '株式会社 常口アトム',
            half: true,
        },
        {
            src: '/src/assets/image/company_logo/toshi-fudousan.png',
            alt: '一般社団法人 投資不動産流通協会',
            half: false,
        },
        {
            src: '/src/assets/image/company_logo/rg.jpg',
            alt: 'レジスタ合同会社',
            half: true,
        },
        {
            src: '/src/assets/image/company_logo/kf.bmp',
            alt: '株式会社きらめき不動産',
            half: true,
        },
        {
            src: '/src/assets/image/company_logo/ln.png',
            alt: 'ライフネクスト株式会社',
            half: true,
        },
        {
            src: '/src/assets/image/company_logo/fn.jpg',
            alt: '株式会社ファクターナイン',
            half: true,
        },
        {
            src: '/src/assets/image/company_logo/as.png',
            alt: '株式会社アナザースカイ',
            half: true,
        },
        {
            src: '/src/assets/image/company_logo/logo_assist_ashiya.png',
            alt: '株式会社アシスト芦屋',
            half: false,
        },
        {
            src: '/src/assets/image/company_logo/mfc.png',
            alt: '株式会社マイエフシー',
            half: false,
        },
        {
            src: '/src/assets/image/company_logo/pisa.png',
            alt: '株式会社PISAコーポレーション',
            half: true,
        },
        {
            src: '/src/assets/image/company_logo/azabu-best-house.png',
            alt: '麻布ベストハウス株式会社',
            half: true,
        },
        {
            src: '/src/assets/image/company_logo/tf.png',
            alt: '大成不動産ホールディングス株式会社',
            half: true,
        },
    ]

    // LP外からアンカー付きで遷移してきた場合のアンカースクロール処理
    useEffect(() => {
        // FAQの読み込み完了後は画面長さが変わるので、FAQ読み込み完了後にスクロールする
        if (window.location.hash && !isLoadingFaqList) {
            // hash先頭シャープを除外してID検索
            const element = document.getElementById(window.location.hash.slice(1))
            const yCoordinate = element ? element.getBoundingClientRect().top : 0
            // ヘッダー高さ分も加味してスクロール
            window.scrollBy({ top: yCoordinate + -64, behavior: 'smooth' })
        }
    }, [window.location.hash, isLoadingFaqList])

    // 親要素の高さを動的に設定するための state
    const [parentHeight, setParentHeight] = useState<string>('auto')
    // 親コンテナの ref
    const containerRef = useRef<HTMLDivElement>(null)
    // 対象の子要素（バッジ群）の ref
    const childRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (!containerRef.current || !childRef.current) return

        const recalcHeight = () => {
            const containerRect = containerRef.current!.getBoundingClientRect()
            const childRect = childRef.current!.getBoundingClientRect()
            // 親の上端から子要素の下端までの距離に50pxを加えた高さを設定
            const desiredHeight = childRect.bottom - containerRect.top + 50
            setParentHeight(`${desiredHeight}px`)
        }

        // 初回計算
        recalcHeight()

        // ウィンドウリサイズ時に再計算する
        window.addEventListener('resize', recalcHeight)
        // すべてのリソース（画像など）の読み込み完了時に再計算する
        window.addEventListener('load', recalcHeight)

        // ResizeObserverを使って対象の要素のサイズ変更を監視する
        const observer = new ResizeObserver(() => {
            recalcHeight()
        })
        observer.observe(containerRef.current)
        observer.observe(childRef.current)

        return () => {
            window.removeEventListener('resize', recalcHeight)
            window.removeEventListener('load', recalcHeight)
            observer.disconnect()
        }
    }, [])

    return (
        <>
            <div className={clsx('overflow-x-hidden', '-m-4', 'md:-m-8')}>
                <Helmet
                    titleTemplate={`${
                        ['local', 'dev', 'stage'].includes(process.env.STAGE ?? '') ? `[${process.env.STAGE}] ` : ''
                    }${t('App.キマール')} - %s`}>
                    <title>
                        {t(
                            'LP.不動産売買取引に不可欠な新規人脈と非公開物件の獲得から、物件紹介と商談成約までの業務プロセスを、ワンストップで一気通貫に対応する業界唯一のDXツール',
                        )}
                    </title>
                </Helmet>
                <div className={clsx('border-b-[16px]', 'border-kimar-primary', 'relative', 'overflow-hidden')}>
                    <div
                        ref={containerRef}
                        className={clsx(
                            'text-center',
                            'md:h-[740px]',
                            'w-full',
                            'flex',
                            'flex-col',
                            'md:flex-row',
                            'overflow-y-auto overflow-x-hidden md:overflow-hidden',
                        )}
                        style={{ height: parentHeight }}>
                        <div
                            className={clsx(
                                'absolute',
                                'top-0',
                                'z-[30]',
                                'w-full',
                                'h-full',
                                'pointer-events-none',
                                'flex',
                                'flex-col',
                                'justify-center',
                                'items-start',
                                'px-6',
                                'py-12',
                            )}>
                            <div className="hidden md:flex flex-col justify-end pl-[20px]">
                                <h1
                                    className={clsx(
                                        'font-bold',
                                        'leading-snug',
                                        'md:text-3xl',
                                        'lg:text-5xl',
                                        'xl:text-6xl',
                                        'mb-8',
                                        'text-kimar-primary',
                                        'text-left',
                                        'lg:pl-4',
                                        'p-2',
                                    )}>
                                    <span className={clsx('bg-white', 'block', 'mb-2', 'p-2')}>物件を仕入れる、</span>
                                    <span className={clsx('bg-white', 'block', 'mb-2', 'p-2')}>買主を探す、</span>
                                    <span className={clsx('bg-white', 'block', 'p-2')}>業販のための管理ツール。</span>
                                </h1>
                                {/* ボタンをh1の下に配置 */}
                                <div
                                    className={clsx(
                                        'flex',
                                        'justify-center',
                                        'px-6',
                                        'w-full',
                                        'max-w-[768px]',
                                        'mx-auto',
                                        'mt-4',
                                    )}
                                />
                            </div>
                        </div>
                        <div className={clsx('absolute', 'md:relative', 'w-full', 'h-full', 'md:w-1/2')}>
                            <motion.img
                                initial={{ opacity: 0, scale: 0 }}
                                whileInView={{ opacity: 1, scale: 1 }}
                                transition={{ type: 'spring', duration: 1, delay: 0.3 }}
                                src="/src/assets/image/page/lp/main-visual/main_visual.svg"
                                alt="Main"
                                className={clsx('absolute', 'z-[3]', '-left-4', 'bottom-0', 'w-full', 'max-w-3xl')}
                            />
                            <motion.img
                                animate={{ rotate: 360, y: [0, -50, 0], x: [0, -30, 0] }}
                                transition={{ duration: 70, ease: [0, 0, 1, 1], repeat: Infinity }}
                                src="/src/assets/image/page/lp/main-visual/main_visual_rock1.svg"
                                className={clsx('absolute', 'z-[2]', '-left-[10%]', '-bottom-[10%]')}
                            />
                            <motion.img
                                animate={{ rotate: -360, y: [0, 80, 0], x: [0, 80, 0] }}
                                transition={{ duration: 70, ease: [0, 0, 1, 1], repeat: Infinity }}
                                src="/src/assets/image/page/lp/main-visual/main_visual_rock2.svg"
                                className={clsx('absolute', 'z-[1]', '-left-[20%]', '-bottom-[20%]')}
                            />
                        </div>
                        <div className={clsx('absolute', 'md:relative', 'w-full', 'h-full', 'md:w-1/2')}>
                            <div className={clsx('absolute', 'top-0', 'z-[3]', 'p-4', 'w-full')}>
                                <div
                                    className={clsx(
                                        'text-[22px]',
                                        'font-bold',
                                        'md:mt-[15vh]',
                                        'max-w-md',
                                        'mx-auto',
                                        'leading-8',
                                    )}>
                                    <p>{t('LP.不動産DXで、業務効率UPと成約数UP')}</p>
                                    <p className={clsx('text-[#007A78]')}>{t('LP.不動産売買プラットフォーム')}</p>
                                </div>
                                <div className={clsx('flex', 'flex-col', 'items-center', 'gap-3', 'max-w-[320px]', 'm-auto')}>
                                    <h1>
                                        <img
                                            className={clsx('align-middle', 'h-auto', 'mx-auto')}
                                            src="/src/assets/image/logo.svg"
                                            alt="キマール"
                                        />
                                    </h1>
                                    <p
                                        className={clsx(
                                            'flex',
                                            'm-auto',
                                            'w-full',
                                            'font-bold',
                                            'text-xl',
                                            'justify-center',
                                            'items-center',
                                        )}>
                                        <span className={clsx('h-1', 'w-1/3', 'bg-gray-700')} />
                                        <span className={clsx('px-2', 'whitespace-nowrap')}>{t('LP.キマール')}</span>
                                        <span className={clsx('h-1', 'w-1/3', 'bg-gray-700')} />
                                    </p>
                                    <div className={clsx('text-xl', 'flex', 'flex-col', 'w-full', 'font-bold')}>
                                        <div
                                            className={clsx(
                                                'top-[26rem]',
                                                'z-[30]',
                                                'w-full',
                                                'h-full',
                                                'flex',
                                                'flex-col',
                                                'justify-center',
                                                'items-start',
                                                'px-6',
                                                'py-6',
                                                'md:hidden', // md以上の画面では非表示（スマホのみ表示）
                                            )}>
                                            <div className="flex flex-col justify-end pl-[20px]">
                                                <h1
                                                    className={clsx(
                                                        'font-bold',
                                                        'leading-snug',
                                                        'text-3xl',
                                                        'text-kimar-primary',
                                                        'text-left',
                                                        'p-2',
                                                    )}>
                                                    <span className={clsx('bg-white', 'block', 'mb-2')}>物件を仕入れる、</span>
                                                    <span className={clsx('bg-white', 'block', 'mb-2')}>買主を探す、</span>
                                                    <span className={clsx('bg-white', 'block')}>業販のための管理ツール。</span>
                                                </h1>
                                                <div
                                                    className={clsx(
                                                        'flex',
                                                        'justify-center',
                                                        'px-6',
                                                        'w-full',
                                                        'max-w-[768px]',
                                                        'mx-auto',
                                                        'mt-4',
                                                    )}
                                                />
                                            </div>
                                        </div>
                                        <div className={clsx('grid', 'grid-cols-2', 'gap-4', 'whitespace-nowrap')}>
                                            <div
                                                className={clsx(
                                                    'flex',
                                                    'items-center',
                                                    'justify-center',
                                                    'gap-1',
                                                    'bg-[#D5ECEA]',
                                                    'rounded-full',
                                                    'py-1',
                                                )}>
                                                <img
                                                    className={clsx('w-4', 'h-4')}
                                                    src="/public/static/img/favicon-32x32.png"
                                                    alt="check"
                                                />
                                                <span>{t('LP.新規人脈')}</span>
                                            </div>
                                            <div
                                                className={clsx(
                                                    'flex',
                                                    'items-center',
                                                    'justify-center',
                                                    'gap-1',
                                                    'bg-[#D5ECEA]',
                                                    'rounded-full',
                                                    'py-1',
                                                )}>
                                                <img
                                                    className={clsx('w-4', 'h-4')}
                                                    src="/public/static/img/favicon-32x32.png"
                                                    alt="check"
                                                />
                                                <span>{t('LP.非公開物件')}</span>
                                            </div>
                                            <div
                                                className={clsx(
                                                    'flex',
                                                    'items-center',
                                                    'justify-center',
                                                    'gap-1',
                                                    'bg-[#D5ECEA]',
                                                    'rounded-full',
                                                    'py-1',
                                                )}>
                                                <img
                                                    className={clsx('w-4', 'h-4')}
                                                    src="/public/static/img/favicon-32x32.png"
                                                    alt="check"
                                                />
                                                <span>{t('LP.売買AI')}</span>
                                            </div>
                                            <div
                                                className={clsx(
                                                    'flex',
                                                    'items-center',
                                                    'justify-center',
                                                    'gap-1',
                                                    'bg-[#D5ECEA]',
                                                    'rounded-full',
                                                    'py-1',
                                                )}>
                                                <img
                                                    className={clsx('w-4', 'h-4')}
                                                    src="/public/static/img/favicon-32x32.png"
                                                    alt="check"
                                                />
                                                <span>{t('LP.効率向上')}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={clsx('flex', 'gap-2', 'm-4', 'justify-center')} ref={childRef}>
                                        <div>
                                            <img src="/src/assets/image/page/lp/badge-patent.svg" alt="特許出願中" />
                                        </div>
                                        <div>
                                            <img
                                                src="/src/assets/image/page/lp/badge-smartphone-compatible.svg"
                                                alt="スマホ対応"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <motion.img
                                animate={{ rotate: 360, y: [0, 80, 0], x: [0, 80, 0] }}
                                transition={{ duration: 70, ease: [0, 0, 1, 1], repeat: Infinity }}
                                src="/src/assets/image/page/lp/main-visual/main_visual_rock3.svg"
                                className={clsx('hidden', 'md:block', 'absolute', 'z-[1]', '-right-[60%]', '-top-[10%]')}
                            />
                            <motion.img
                                animate={{ rotate: -360, y: [0, -80, 0], x: [0, -80, 0] }}
                                transition={{ duration: 70, ease: [0, 0, 1, 1], repeat: Infinity }}
                                src="/src/assets/image/page/lp/main-visual/main_visual_rock4.svg"
                                className={clsx('hidden', 'md:block', 'absolute', 'z-[2]', '-right-[35%]', '-top-[30%]')}
                            />
                        </div>
                    </div>
                </div>
                <div id="about" className={clsx('m-6', 'md:m-10')}>
                    <div className={clsx('text-5xl', 'font-bold', 'w-full')}>
                        <img
                            className={clsx('mx-auto', 'object-none', 'p-4')}
                            src="/src/assets/image/icon/check-medium.svg"
                            alt="check"
                        />
                        <div className={clsx('flex', 'justify-center')}>
                            <Trans i18nKey="LP.キマールとは">
                                <span className={clsx('text-kimar-primary')}>キマール</span>
                                <span>とは</span>
                            </Trans>
                        </div>
                    </div>
                    <div className={clsx('flex', 'flex-col', 'items-center', 'lg:justify-center', 'lg:flex-row', 'p-4')}>
                        <img
                            className={clsx('fit-content', 'w-4/5', 'max-w-[400px]')}
                            src="/src/assets/image/page/lp/intro.svg"
                            alt="キマールとは"
                        />
                        <article className={clsx('lg:p-8', 'w-full', 'max-w-xl', 'text-2xl')}>
                            <p className={clsx('m-4', 'text-center', 'lg:text-left', 'leading-relaxed')}>
                                <Trans i18nKey="LP.不動産売買取引に不可欠な新規人脈と非公開物件の獲得から、物件紹介と商談成約までの業務プロセスを、ワンストップで一気通貫に対応する業界唯一のDXツールです。">
                                    不動産売買取引に不可欠な
                                    <strong className={clsx('border-b-4', 'border-kimar-primary')}>
                                        新規人脈と非公開物件の獲得から、物件紹介と商談成約
                                    </strong>
                                    までの業務プロセスを、ワンストップで一気通貫に対応する業界唯一のDXツールです。
                                </Trans>
                            </p>
                            <p className={clsx('m-4', 'text-center', 'lg:text-left', 'leading-relaxed')}>
                                <Trans i18nKey="LP.楽に早く効果的に、不動産人脈と物件情報を直接つなげることで、不動産売買成約までの最短ルートを提供します。">
                                    <strong className={clsx('border-b-4', 'border-kimar-primary')}>楽に早く効果的に</strong>
                                    、不動産人脈と物件情報を
                                    <strong className={clsx('border-b-4', 'border-kimar-primary')}>直接つなげる</strong>
                                    ことで、不動産売買成約までの
                                    <strong className={clsx('border-b-4', 'border-kimar-primary')}>最短ルート</strong>
                                    を提供します。
                                </Trans>
                            </p>
                        </article>
                    </div>
                </div>
                <div className={clsx('bg-kimar-primary', 'p-10', 'flex', 'flex-col', 'justify-center', 'items-center')}>
                    <div className={clsx('max-w-[1280px]', 'w-full', 'mx-auto', 'my-0')}>
                        <iframe
                            className={clsx('w-full', 'h-[480px]', 'lg:h-[720px]')}
                            title="movie"
                            width="1280"
                            src="https://www.youtube.com/embed/NHperwveDT0"
                            frameBorder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        />
                    </div>
                    <AnchorLink
                        href="#download"
                        className={clsx(
                            'w-full',
                            'max-w-[768px]',
                            'rounded-full',
                            'bg-kimar-accent',
                            'font-bold',
                            'text-xl',
                            'flex',
                            'justify-center',
                            'py-5',
                            'my-5',
                            'outline',
                            'outline-kimar-accent',
                            'hover:bg-white',
                            'hover:outline-2',
                            'hover:outline-kimar-accent',
                            'transition',
                        )}
                        offset="64">
                        {t('LP.資料ダウンロードはこちら')}
                    </AnchorLink>
                </div>
                <div className={clsx('m-10')}>
                    <div className={clsx('text-3xl', 'font-bold', 'w-full', 'mb-4')}>
                        <img className={clsx('mx-auto', 'p-4')} src="/src/assets/image/icon/check.svg" alt="check" />
                        <div className={clsx('flex', 'flex-wrap', 'justify-center')}>
                            <Trans i18nKey="LP.キマールで解決できます">
                                <span className={clsx('inline-block')}>
                                    <span className={clsx('text-kimar-primary')}>キマール</span>で
                                </span>
                                <span>解決できます</span>
                            </Trans>
                        </div>
                    </div>
                    <div className={clsx('flex', 'flex-col', 'gap-y-8', 'lg:block')}>
                        {voices.map((voice, index) => (
                            <div key={index} className={clsx('block', 'sticky', voice.translate)}>
                                <motion.div
                                    className={clsx(
                                        'flex',
                                        'bg-[#DFF5F4]',
                                        'relative',
                                        'rounded-full',
                                        'p-4',
                                        'items-center',
                                        'mx-auto',
                                        'lg:max-w-sm',
                                    )}
                                    animate={{
                                        y: [0, voice.angle === 'left' ? 3 : -3, 0],
                                        x: [0, voice.angle === 'left' ? -3 : 3, 0],
                                    }}
                                    transition={{ duration: 5, repeat: Infinity, delay: 0.3 * index }}>
                                    <span
                                        aria-hidden="true"
                                        className={clsx(
                                            'absolute',
                                            'w-9',
                                            'h-9',
                                            'lg:w-16',
                                            'lg:h-16',
                                            'rounded-full',
                                            voice.angle === 'left' ? ['left-2'] : ['right-4'],
                                            '-bottom-4',
                                            'lg:-bottom-8',
                                            'bg-[#DFF5F4]',
                                            '-z-[1]',
                                        )}
                                    />
                                    <span
                                        aria-hidden="true"
                                        className={clsx(
                                            'absolute',
                                            'w-3.5',
                                            'h-3.5',
                                            'lg:w-6',
                                            'lg:h-6',
                                            'rounded-full',
                                            '-bottom-9',
                                            voice.angle === 'left' ? ['left-2'] : ['right-4'],
                                            'lg:-bottom-16',
                                            'bg-[#DFF5F4]',
                                            '-z-[1]',
                                        )}
                                    />
                                    <div
                                        className={clsx(
                                            'rounded-full',
                                            'border-2',
                                            'border-kimar-primary',
                                            'p-2',
                                            'bg-white',
                                            'm-2',
                                            'mr-6',
                                            'mr-6',
                                        )}>
                                        <img src={`/src/assets/image/page/lp/voice/voice-${voice.num}.svg`} alt={voice.content} />
                                    </div>
                                    <div className={clsx('flex-1')}>
                                        <div className={clsx('text-kimar-primary')}>VOICE {voice.num}</div>
                                        <p>{voice.content}</p>
                                    </div>
                                </motion.div>
                            </div>
                        ))}
                        <div className={clsx('flex', 'justify-center')}>
                            <AnchorLink
                                href="#download"
                                className={clsx(
                                    'w-full',
                                    'max-w-[768px]',
                                    'rounded-full',
                                    'bg-kimar-accent',
                                    'font-bold',
                                    'text-xl',
                                    'flex',
                                    'justify-center',
                                    'py-5',
                                    'my-5',
                                    'outline',
                                    'outline-kimar-accent',
                                    'hover:bg-white',
                                    'hover:outline-2',
                                    'hover:outline-kimar-accent',
                                    'transition',
                                )}
                                offset="64">
                                {t('LP.資料ダウンロードはこちら')}
                            </AnchorLink>
                        </div>
                    </div>
                </div>
                <div className={clsx('flex', 'flex-col', 'items-center', 'bg-[#DFF5F4]', 'py-8', 'px-6')}>
                    <div className={clsx('text-3xl', 'font-bold', 'w-full', 'mb-4')}>
                        <img className={clsx('mx-auto', 'p-4')} src="/src/assets/image/icon/check.svg" alt="check" />
                        <div className={clsx('flex', 'flex-wrap', 'justify-center')}>
                            <Trans i18nKey="LP.キマールの出会いサポート機能">
                                <span className={clsx('text-kimar-primary')}>キマール</span>の 出会いサポート機能
                            </Trans>
                        </div>
                    </div>
                    <div className={clsx('text-center')}>
                        {t('LP.新規人脈・新規物件を獲得')}
                        <br />
                        {t('LP.キマール会員急増中!! 新規人脈・物件のマッチング確率が更に高まっていきます')}
                    </div>
                    <div className={clsx('flex', 'flex-wrap', 'lg:flex-nowrap', 'justify-center', 'gap-4', 'mt-6', 'mb-12')}>
                        {encounterSupport.map((support, index) => (
                            <div key={`encounter-support-${index}`} className={clsx('flex', 'flex-col', 'grow', 'gap-y-2')}>
                                <div className={clsx('rounded-xl', 'bg-white', 'p-5')}>
                                    <div className={clsx('flex', 'flex-col', 'items-center', 'text-center')}>
                                        <img className={clsx('p-2')} src={support.img} alt={support.title} />
                                        <div
                                            className={clsx(
                                                'text-2xl',
                                                'font-bold',
                                                'w-full',
                                                'mb-4',
                                                'text-kimar-primary',
                                                'font-bold',
                                            )}>
                                            {support.title}
                                        </div>
                                        <p
                                            className={clsx('font-bold')}
                                            dangerouslySetInnerHTML={{ __html: support.description }}
                                        />
                                    </div>
                                </div>
                                <p
                                    className={clsx('text-center', 'text-sm')}
                                    dangerouslySetInnerHTML={{ __html: support.annotation }}
                                />
                            </div>
                        ))}
                    </div>
                    <div className={clsx('text-3xl', 'font-bold', 'w-full', 'mb-4')}>
                        <img className={clsx('mx-auto', 'p-4')} src="/src/assets/image/icon/check.svg" alt="check" />
                        <div className={clsx('flex', 'flex-wrap', 'justify-center')}>
                            <Trans i18nKey="LP.キマールの成約サポート機能">
                                <span className={clsx('text-kimar-primary')}>キマール</span>
                                <span>の成約サポート機能</span>
                            </Trans>
                        </div>
                    </div>
                    <div className={clsx('text-center')}>
                        {t('LP.取得した情報資産を効率よくフル活用し物件と顧客をマッチング')}
                        <br />
                        {t('LP.従来のアナログ業務をDX化することで業務効率と精度が劇的に高まります')}
                    </div>
                    <div className={clsx('flex', 'flex-wrap', 'lg:flex-nowrap', 'justify-center', 'gap-4', 'mt-6')}>
                        {contractSupport.map((support, index) => (
                            <div
                                key={`contract-support-${index}`}
                                className={clsx('flex', 'flex-col', 'grow', 'rounded-xl', 'bg-white', 'p-5')}>
                                <div className={clsx('flex', 'flex-col', 'items-center', 'text-center')}>
                                    <img className={clsx('p-2')} src={support.img} alt={support.title} />
                                    <div
                                        className={clsx(
                                            'text-2xl',
                                            'font-bold',
                                            'w-full',
                                            'mb-4',
                                            'text-kimar-primary',
                                            'font-bold',
                                        )}>
                                        {support.title}
                                    </div>
                                    <p className={clsx('font-bold')} dangerouslySetInnerHTML={{ __html: support.description }} />
                                </div>
                            </div>
                        ))}
                    </div>
                    <AnchorLink
                        href="#download"
                        className={clsx(
                            'w-full',
                            'max-w-[768px]',
                            'rounded-full',
                            'bg-kimar-accent',
                            'font-bold',
                            'text-xl',
                            'flex',
                            'justify-center',
                            'py-5',
                            'my-5',
                            'outline',
                            'outline-kimar-accent',
                            'hover:bg-white',
                            'hover:outline-2',
                            'hover:outline-kimar-accent',
                            'transition',
                        )}
                        offset="64">
                        {t('LP.資料ダウンロードはこちら')}
                    </AnchorLink>
                </div>
                <div id="company" className={clsx('bg-white', 'py-8', 'flex', 'flex-col', 'justify-center', 'items-center')}>
                    <div className={clsx('text-3xl', 'font-bold', 'w-full', 'mb-4')}>
                        <img className={clsx('mx-auto', 'p-4')} src="/src/assets/image/icon/check.svg" alt="check" />
                        <div className={clsx('flex', 'justify-center')}>
                            <span>{t('LP.導入企業例')}</span>
                        </div>
                    </div>
                    <div className={clsx('text-center')}>({t('LP.順不同、敬称略')})</div>
                    <div className={clsx('max-w-[1280px]', 'grid', 'grid-cols-2', 'md:grid-cols-3', 'lg:grid-cols-5')}>
                        {companies.map((company, index) => (
                            <figure key={index} className={clsx('p-4', 'my-auto')}>
                                <img className={clsx('mx-auto')} src={company.src} alt={company.alt} />
                            </figure>
                        ))}
                    </div>
                    <div className={clsx('px-6', 'w-full', 'max-w-[768px]')}>
                        <AnchorLink
                            href="#download"
                            className={clsx(
                                'w-full',
                                'rounded-full',
                                'bg-kimar-accent',
                                'font-bold',
                                'text-xl',
                                'flex',
                                'justify-center',
                                'py-5',
                                'outline',
                                'outline-kimar-accent',
                                'hover:bg-white',
                                'hover:outline-2',
                                'hover:outline-kimar-accent',
                                'transition',
                            )}
                            offset="64">
                            {t('LP.資料ダウンロードはこちら')}
                        </AnchorLink>
                    </div>
                </div>
                <div id="interview" className={clsx('bg-[#DFF5F4]', 'p-6')}>
                    <div className={clsx('text-3xl', 'font-bold', 'w-full', 'mb-4')}>
                        <img className={clsx('mx-auto', 'p-4')} src="/src/assets/image/icon/check.svg" alt="check" />
                        <div className={clsx('flex', 'justify-center')}>
                            <span>{t('LP.お客様の声')}</span>
                        </div>
                    </div>
                    <p className={clsx('text-center', 'mb-8')}>{t('LP.実際にキマールを使用したお客様の感想を紹介します。')}</p>
                    <article
                        id="interview5"
                        className={clsx(
                            'bg-white',
                            'rounded-3xl',
                            'flex',
                            'flex-col',
                            'items-center',
                            'mb-5',
                            'p-6',
                            'max-w-[968px]',
                            'mx-auto',
                        )}>
                        <div
                            className={clsx(
                                'flex',
                                'flex-wrap',
                                'sm:flex-nowrap',
                                'flex-row-reverse',
                                'justify-center',
                                'gap-4',
                            )}>
                            <div className={clsx('flex', 'flex-col', 'justify-center', 'w-[160px]', 'min-w-[160px]')}>
                                <img
                                    className={clsx('my-4', 'max-h-[120px]', 'object-contain')}
                                    src="/src/assets/image/company_logo/myfc-advisors.jpg"
                                    alt="マイエフシー・アドバイザーズ株式会社"
                                />
                                <p className={clsx('text-xs', 'text-center')}>
                                    マイエフシー・アドバイザーズ株式会社
                                    <br />
                                    白石様
                                </p>
                            </div>
                            <div className={clsx('flex', 'flex-col', 'grow', 'mb-3')}>
                                <h3 className={clsx('text-kimar-primary', 'text-2xl', 'mb-4')}>
                                    『物件紹介に30分、1時間かかっていたのが、5分10分で送れるようになりました！』
                                </h3>
                                {interview5Visible ? (
                                    <div>
                                        <h4 className={clsx('font-bold', 'mb-2')}>
                                            <span className={clsx('leading-3')}>——</span>会社概要と事業の特徴を教えてください
                                        </h4>
                                        <p className={clsx('mb-4', 'text-sm')}>
                                            マイエフシー・アドバイザーズは一棟収益の売買をメインでやっています。
                                            <br />
                                            特徴としては、種別問わず何でも買えるところが強みかなと思っています。あとは、単純に転売するだけではなく、長期で保有することが出来るので、多少金額が高くても場所が良ければ他社より高い金額で買うことが出来るのが特徴です。
                                            <br />
                                            私は買取をメインにやっていて、基本的には自社で買う物件の仕入れなのですが、弊社では管理もしているので、抱えているオーナーさんから売却の相談があった時には買い手さんを探してあげるようなこともやっています。
                                        </p>
                                        <h4 className={clsx('font-bold', 'mb-2')}>
                                            <span className={clsx('leading-3')}>——</span>
                                            <span className={clsx('text-kimar-primary')}>キマール</span>
                                            導入前の課題を教えてください
                                        </h4>
                                        <p className={clsx('mb-4', 'text-sm')}>
                                            基本的に元々何も管理していなくて、来た情報に関しては代表や営業に見せて、これを買えるか買えないか判断をするような程度でした。
                                            <br />
                                            基本的には何も残していなかった状況だったので、もらった情報の中で買いニーズが来たときに「そういえばどこかでこういった物件をもらったことがあるな」と思い出すことは多々あったのですが、具体的に覚えてないので探すことも出来ず...結果それで紹介できていないということが結構ありました。
                                            <br />
                                            <span className={clsx('text-kimar-primary')}>キマール</span>を使ってからは
                                            <b>物件登録していくことで、そういったことが防げるよう</b>になりました。
                                        </p>
                                        <h4 className={clsx('font-bold', 'mb-2')}>
                                            <span className={clsx('leading-3')}>——</span>
                                            <span className={clsx('text-kimar-primary')}>キマール</span>
                                            の導入はスムーズでしたか？
                                        </h4>
                                        <p className={clsx('mb-4', 'text-sm')}>
                                            最初の設定はリマールさんが全面的にフォローしてくれるので簡単ですよね。導入後の運用もそんなに
                                            <b>難しい作業もなくシンプル</b>
                                            です。わからないところがあっても電話で聞きながらやったので、特段難しいということはなく問題なかったなと思います。
                                        </p>
                                        <h4 className={clsx('font-bold', 'mb-2')}>
                                            <span className={clsx('leading-3')}>——</span>実際に
                                            <span className={clsx('text-kimar-primary')}>キマール</span>
                                            を導入し、業務にはどのような変化がありましたか？
                                        </h4>
                                        <p className={clsx('mb-4', 'text-sm')}>
                                            メールを送る手間というところが<b>一番効率化を図れているな</b>
                                            と感じます。元々アナログで一通一通メールを送っていたので、物件によっては一つの物件を送るだけで30分とか1時間とかかかっていたのが、
                                            <b>5分10分で送れるようになったことに関してはすごく効率化を図れています</b>。<br />
                                            物件をラベルで管理出来るので、紹介の時にもラベル検索をして送ることで楽になりました。元々紹介漏れも以前はあって、それもメールを辿って「送ったっけ？送ってないっけ？」と読み返すのが結構手間だったので、
                                            <span className={clsx('text-kimar-primary')}>キマール</span>を使うと
                                            <b>「いついつ送ってますよ」というのがすぐわかる</b>じゃないですか。
                                            そういった部分でもわかりやすいですね。物件紹介後のお客様の反応に関しても、やっぱり見てる方はすごく見ているし、見てない方って一定数いるので、お客様がメールを見ている、見ていないを一応ちゃんと確認していて、何通送っても見ない方には送らないようにして
                                            <b>有効顧客の精査整理</b>というところも今は出来ているかなと思います。
                                            <br />
                                            あとは地図の機能で、例えば城南エリアで探しているとか、城北エリアで探しているとかニーズをもらった時にも
                                            <span className={clsx('text-kimar-primary')}>キマール</span>の
                                            <b>地図を見ると一発でわかるのでそこがシンプルに使いやすいなと思っています</b>。<br />
                                            物件概要書も、弊社で買った物件は弊社のひな形で作り直していたのですが、
                                            <span className={clsx('text-kimar-primary')}>キマール</span>で物件登録をすると
                                            <b>自動で作れるので効率化</b>になっているなと感じます。
                                        </p>
                                        <h4 className={clsx('font-bold', 'mb-2')}>
                                            <span className={clsx('leading-3')}>——</span>
                                            インタビューにお答えいただき、ありがとうございました
                                        </h4>
                                    </div>
                                ) : (
                                    <div>
                                        <h4 className={clsx('font-bold', 'mb-2')}>
                                            <span className={clsx('leading-3')}>——</span>
                                            <span className={clsx('text-kimar-primary')}>キマール</span>
                                            導入前の課題を教えてください
                                        </h4>
                                        <p className={clsx('mb-4', 'text-sm')}>
                                            基本的に元々何も管理していなくて、来た情報に関しては代表や営業に見せて、これを買えるか買えないか判断をするような程度でした。
                                            <br />
                                            基本的には何も残していなかった状況だったので、もらった情報の中で買いニーズが来たときに「そういえばどこかでこういった物件をもらったことがあるな」と思い出すことは多々あったのですが、具体的に覚えてないので探すことも出来ず...結果それで紹介できていないということが結構ありました。
                                            <br />
                                            <span className={clsx('text-kimar-primary')}>キマール</span>を使ってからは
                                            <b>物件登録していくことで、そういったことが防げる</b>ようになりました。
                                        </p>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div>
                            <CButton
                                className={clsx('rounded-full', 'bg-kimar-primary', 'text-white', 'px-3', 'py-1')}
                                onClick={() => setInterview5Visible((oldValue) => !oldValue)}>
                                {interview5Visible ? (
                                    <AnchorLink offset="64" href="#interview5" className={clsx('flex', 'flex-row', 'gap-1')}>
                                        <span>{t('LP.インタビューの内容を閉じる')}</span>
                                        <img src="/src/assets/image/page/lp/arrow_up.svg" alt="インタビューの内容を閉じる" />
                                    </AnchorLink>
                                ) : (
                                    <AnchorLink offset="64" href="#interview5" className={clsx('flex', 'flex-row', 'gap-1')}>
                                        <span>
                                            <Trans i18nKey="LP.インタビューの内容を詳しく見る">
                                                <span className={clsx('inline-block')}>インタビューの内容を</span>
                                                <span className={clsx('inline-block')}>詳しく見る</span>
                                            </Trans>
                                        </span>
                                        <img
                                            src="/src/assets/image/page/lp/arrow_down.svg"
                                            alt="インタビューの内容を詳しく見る"
                                        />
                                    </AnchorLink>
                                )}
                            </CButton>
                        </div>
                    </article>
                    <article
                        id="interview4"
                        className={clsx(
                            'bg-white',
                            'rounded-3xl',
                            'flex',
                            'flex-col',
                            'items-center',
                            'mb-5',
                            'p-6',
                            'max-w-[968px]',
                            'mx-auto',
                        )}>
                        <div className={clsx('flex', 'flex-wrap', 'sm:flex-nowrap', 'flex-row', 'justify-center', 'gap-4')}>
                            <div className={clsx('flex', 'flex-col', 'justify-center', 'w-[160px]', 'min-w-[160px]')}>
                                <img
                                    className={clsx('my-4')}
                                    src="/src/assets/image/company_logo/assist_ashiya.png"
                                    alt="株式会社アシスト芦屋"
                                />
                                <p className={clsx('text-xs', 'text-center')}>
                                    株式会社アシスト芦屋
                                    <br />
                                    苅谷様
                                </p>
                            </div>
                            <div className={clsx('flex', 'flex-col', 'grow', 'mb-3')}>
                                <h3 className={clsx('text-kimar-primary', 'text-2xl', 'mb-4')}>
                                    『物件や顧客に関するあらゆる情報を<strong>キマール</strong>で管理しています！』
                                </h3>
                                {interview4Visible ? (
                                    <div>
                                        <h4 className={clsx('font-bold', 'mb-2')}>
                                            <span className={clsx('leading-3')}>——</span>会社概要と事業の特徴を教えてください
                                        </h4>
                                        <p className={clsx('mb-4', 'text-sm')}>
                                            株式会社アシスト芦屋は賃貸マンションの管理、不動産の売買仲介等を行っている会社です。売買仲介業務については私1人で担当していて、収益物件や土地、区分マンション等、種別に関わらずあらゆる不動産の売買仲介を行っています。
                                            <br />
                                            事業の特徴としては、地元の地主さんや投資家さんから相続や資産に関する不動産のご相談を受けることが多いので、そういった分野のお悩み解決も得意としています。
                                            <br />
                                            また、既存のお客様から新たなお客様をご紹介いただくことが多いことも、特徴の一つですね。
                                        </p>
                                        <h4 className={clsx('font-bold', 'mb-2')}>
                                            <span className={clsx('leading-3')}>——</span>
                                            <span className={clsx('text-kimar-primary')}>キマール</span>
                                            を導入するに至ったきっかけを教えてください
                                        </h4>
                                        <p className={clsx('mb-4', 'text-sm')}>
                                            営業活動をしていく中で、もっと効率よく動くためにはどうしたらいいのか？と考えていた時に、不動産テックイベントで「
                                            <span className={clsx('text-kimar-primary')}>キマール</span>」の存在を知りました。
                                            <br />
                                            <span className={clsx('text-kimar-primary')}>キマール</span>の
                                            <b>物件・顧客管理機能、物件紹介機能</b>を使えば課題を解決できると思い、
                                            <span className={clsx('text-kimar-primary')}>キマール</span>の導入に至りました。
                                        </p>
                                        <h4 className={clsx('font-bold', 'mb-2')}>
                                            <span className={clsx('leading-3')}>——</span>どんな場面で
                                            <span className={clsx('text-kimar-primary')}>キマール</span>
                                            を活用していますか？
                                        </h4>
                                        <p className={clsx('mb-4', 'text-sm')}>
                                            物件情報や顧客情報を入手したら、まず
                                            <span className={clsx('text-kimar-primary')}>キマール</span>{' '}
                                            へ情報を登録するようにしています。
                                            <b>
                                                物件や顧客に関するあらゆる情報を
                                                <span className={clsx('text-kimar-primary')}>キマール</span> で管理している
                                            </b>
                                            ので、データベースとしても使っていますね。
                                            <br />
                                            また、顧客へ物件紹介を行う際も、
                                            <span className={clsx('text-kimar-primary')}>キマール</span>
                                            を使っています。
                                        </p>
                                        <h4 className={clsx('font-bold', 'mb-2')}>
                                            <span className={clsx('leading-3')}>——</span>実際に
                                            <span className={clsx('text-kimar-primary')}>キマール</span>
                                            を導入し、業務にはどのような変化がありましたか？
                                        </h4>
                                        <p className={clsx('mb-4', 'text-sm')}>
                                            業務の効率化や、情報の整理がとても楽になりました。あとは、
                                            <b>社内での情報共有が格段にスムーズになりました</b>ね。
                                            <br />
                                            具体的には、売買仲介業務は私が1人で担当しているので、私の営業活動や行動が社内でなかなか共有ができていませんでした。
                                            <span className={clsx('text-kimar-primary')}>キマール</span>
                                            を導入してからは、私の行動履歴や物件の登録状況、顧客への紹介状況等が各メンバーにメールで共有されるので、
                                            <b>情報共有が円滑になりました</b>。
                                        </p>
                                        <h4 className={clsx('font-bold', 'mb-2')}>
                                            <span className={clsx('leading-3')}>——</span>
                                            最後に、今後弊社に期待していること、改善してほしいことがあれば教えてください
                                        </h4>
                                        <p className={clsx('mb-4', 'text-sm')}>
                                            こまめに機能をアップデートされている印象を受けているので、<b>十分満足しています</b>。
                                        </p>
                                        <h4 className={clsx('font-bold', 'mb-2')}>
                                            <span className={clsx('leading-3')}>——</span>
                                            インタビューにお答えいただき、ありがとうございました
                                        </h4>
                                    </div>
                                ) : (
                                    <div>
                                        <h4 className={clsx('font-bold', 'mb-2')}>
                                            <span className={clsx('leading-3')}>——</span>どんな場面で
                                            <span className={clsx('text-kimar-primary')}>キマール</span>
                                            を活用していますか？
                                        </h4>
                                        <p className={clsx('mb-4', 'text-sm')}>
                                            物件情報や顧客情報を入手したら、まず
                                            <span className={clsx('text-kimar-primary')}>キマール</span>{' '}
                                            へ情報を登録するようにしています。
                                            <b>
                                                物件や顧客に関するあらゆる情報を
                                                <span className={clsx('text-kimar-primary')}>キマール</span> で管理している
                                            </b>
                                            ので、データベースとしても使っていますね。
                                            <br />
                                            また、顧客へ物件紹介を行う際も、
                                            <span className={clsx('text-kimar-primary')}>キマール</span>
                                            を使っています。
                                        </p>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div>
                            <CButton
                                className={clsx('rounded-full', 'bg-kimar-primary', 'text-white', 'px-3', 'py-1')}
                                onClick={() => setInterview4Visible((oldValue) => !oldValue)}>
                                {interview4Visible ? (
                                    <AnchorLink offset="64" href="#interview4" className={clsx('flex', 'flex-row', 'gap-1')}>
                                        <span>{t('LP.インタビューの内容を閉じる')}</span>
                                        <img src="/src/assets/image/page/lp/arrow_up.svg" alt="インタビューの内容を閉じる" />
                                    </AnchorLink>
                                ) : (
                                    <AnchorLink offset="64" href="#interview4" className={clsx('flex', 'flex-row', 'gap-1')}>
                                        <span>
                                            <Trans i18nKey="LP.インタビューの内容を詳しく見る">
                                                <span className={clsx('inline-block')}>インタビューの内容を</span>
                                                <span className={clsx('inline-block')}>詳しく見る</span>
                                            </Trans>
                                        </span>
                                        <img
                                            src="/src/assets/image/page/lp/arrow_down.svg"
                                            alt="インタビューの内容を詳しく見る"
                                        />
                                    </AnchorLink>
                                )}
                            </CButton>
                        </div>
                    </article>
                    <article
                        id="interview3"
                        className={clsx(
                            'bg-white',
                            'rounded-3xl',
                            'flex',
                            'flex-col',
                            'items-center',
                            'mb-5',
                            'p-6',
                            'max-w-[968px]',
                            'mx-auto',
                        )}>
                        <div
                            className={clsx(
                                'flex',
                                'flex-wrap',
                                'sm:flex-nowrap',
                                'flex-row-reverse',
                                'justify-center',
                                'gap-4',
                            )}>
                            <div className={clsx('flex', 'flex-col', 'justify-center', 'w-[160px]', 'min-w-[160px]')}>
                                <img
                                    className={clsx('my-4')}
                                    src="/src/assets/image/company_logo/st_grande_w.png"
                                    alt="株式会社セントグランデＷ"
                                />
                                <p className={clsx('text-xs', 'text-center')}>株式会社セントグランデＷ様</p>
                            </div>
                            <div className={clsx('flex', 'flex-col', 'grow', 'mb-3')}>
                                <h3 className={clsx('text-kimar-primary', 'text-2xl', 'mb-4')}>
                                    『社内で物件情報を共有するのが
                                    <strong className={clsx('border-b-[3px]', 'border-kimar-primary')}>とても楽に</strong>
                                    なりました！』
                                </h3>
                                {interview3Visible ? (
                                    <div>
                                        <h4 className={clsx('font-bold', 'mb-2')}>
                                            <span className={clsx('leading-3')}>——</span>会社概要と事業の特徴を教えてください
                                        </h4>
                                        <p className={clsx('mb-4', 'text-sm')}>
                                            株式会社セントグランデＷは、都内を中心とした不動産売買、仲介、賃貸、管理まで幅広く活動している会社です。
                                            恵比寿・広尾・代官山・神宮前・青山・銀座等のプレミアムエリア不動産に特化している点が、弊社の特徴と言えます。
                                            <br />
                                            私自身は、不動産を取得し、付加価値をつけて売却する業務がメインです。
                                        </p>
                                        <h4 className={clsx('font-bold', 'mb-2')}>
                                            <span className={clsx('leading-3')}>——</span>
                                            <span className={clsx('text-kimar-primary')}>キマール</span>
                                            導入前の課題を教えてください
                                        </h4>
                                        <p className={clsx('mb-4', 'text-sm')}>
                                            不動産の仕入れをメインとしているので、物件情報の管理を簡略化することが主な課題でした。
                                            いつ・だれが・どの物件情報を入手したのかを地図に書き込んで管理していたので、
                                            <b>「地図を見ないとわからない」という管理方法を変えたい</b>と考えていました。
                                        </p>
                                        <h4 className={clsx('font-bold', 'mb-2')}>
                                            <span className={clsx('leading-3')}>——</span>
                                            <span className={clsx('text-kimar-primary')}>キマール</span>
                                            の導入はスムーズでしたか？
                                        </h4>
                                        <p className={clsx('mb-4', 'text-sm')}>
                                            <span className={clsx('text-kimar-primary')}>キマール</span>は
                                            <b>説明書を読まなくても使えるぐらい操作が簡単</b>なので、スムーズに活用できました。
                                        </p>
                                        <h4 className={clsx('font-bold', 'mb-2')}>
                                            <span className={clsx('leading-3')}>——</span>
                                            <span className={clsx('text-kimar-primary')}>キマール</span>
                                            の操作性が、導入の決め手になったのでしょうか？
                                        </h4>
                                        <p className={clsx('mb-4', 'text-sm')}>
                                            おっしゃる通りです。様々なシステムを比較検討した結果、
                                            <b>
                                                <span className={clsx('text-kimar-primary')}>キマール</span>
                                                が一番シンプルで使いやすかった
                                            </b>
                                            ため、導入に至りました。システムを活用していくにあたって、操作性は大事ですからね。
                                        </p>
                                        <h4 className={clsx('font-bold', 'mb-2')}>
                                            <span className={clsx('leading-3')}>——</span>実際に
                                            <span className={clsx('text-kimar-primary')}>キマール</span>
                                            を導入し、業務にはどのような変化がありましたか？
                                        </h4>
                                        <p className={clsx('mb-4', 'text-sm')}>
                                            社内で物件情報を共有するのがとても楽になりました。
                                            <br />
                                            たくさんの物件情報を<span className={clsx('text-kimar-primary')}>キマール</span>
                                            に登録していますが、具体的に購入を検討する情報は、登録した物件情報のうち数件程度です。
                                            <br />
                                            <b>
                                                <span className={clsx('text-kimar-primary')}>キマール</span>
                                                はラベルで物件情報を管理することができる
                                            </b>
                                            ので、購買意欲が高い物件には「この物件を買いたい」という旨のラベルを貼り付けて、社内で情報共有しています。
                                            <br />
                                            ラベルを見るだけで物件状況が把握できるので、
                                            <b>仕入れ業務の効率化にもつながっている</b>と感じます。
                                        </p>
                                        <h4 className={clsx('font-bold', 'mb-2')}>
                                            <span className={clsx('leading-3')}>——</span>
                                            最後に、今後弊社に期待していること、改善してほしいことがあれば教えてください
                                        </h4>
                                        <p className={clsx('mb-4', 'text-sm')}>
                                            <span className={clsx('text-kimar-primary')}>キマール</span>
                                            の地図機能も便利なので活用しているのですが、地図上で複数の物件情報を同時に表示できたら嬉しいです。
                                        </p>
                                        <h4 className={clsx('font-bold', 'mb-2')}>
                                            <span className={clsx('leading-3')}>——</span>
                                            インタビューにお答えいただき、ありがとうございました
                                        </h4>
                                    </div>
                                ) : (
                                    <div>
                                        <h4 className={clsx('font-bold', 'mb-2')}>
                                            <span className={clsx('leading-3')}>——</span>
                                            <span className={clsx('text-kimar-primary')}>キマール</span>
                                            の操作性が、導入の決め手になったのでしょうか？
                                        </h4>
                                        <p className={clsx('mb-4', 'text-sm')}>
                                            おっしゃる通りです。様々なシステムを比較検討した結果、
                                            <b>
                                                <span className={clsx('text-kimar-primary')}>キマール</span>
                                                が一番シンプルで使いやすかった
                                            </b>
                                            ため、導入に至りました。システムを活用していくにあたって、操作性は大事ですからね。
                                        </p>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div>
                            <CButton
                                className={clsx('rounded-full', 'bg-kimar-primary', 'text-white', 'px-3', 'py-1')}
                                onClick={() => setInterview3Visible((oldValue) => !oldValue)}>
                                {interview3Visible ? (
                                    <AnchorLink offset="64" href="#interview3" className={clsx('flex', 'flex-row', 'gap-1')}>
                                        <span>{t('LP.インタビューの内容を閉じる')}</span>
                                        <img src="/src/assets/image/page/lp/arrow_up.svg" alt="インタビューの内容を閉じる" />
                                    </AnchorLink>
                                ) : (
                                    <AnchorLink offset="64" href="#interview3" className={clsx('flex', 'flex-row', 'gap-1')}>
                                        <span>
                                            <Trans i18nKey="LP.インタビューの内容を詳しく見る">
                                                <span className={clsx('inline-block')}>インタビューの内容を</span>
                                                <span className={clsx('inline-block')}>詳しく見る</span>
                                            </Trans>
                                        </span>
                                        <img
                                            src="/src/assets/image/page/lp/arrow_down.svg"
                                            alt="インタビューの内容を詳しく見る"
                                        />
                                    </AnchorLink>
                                )}
                            </CButton>
                        </div>
                    </article>
                    <article
                        id="interview2"
                        className={clsx(
                            'bg-white',
                            'rounded-3xl',
                            'flex',
                            'flex-col',
                            'items-center',
                            'mb-5',
                            'p-6',
                            'max-w-[968px]',
                            'mx-auto',
                        )}>
                        <div className={clsx('flex', 'flex-wrap', 'sm:flex-nowrap', 'flex-row', 'justify-center', 'gap-4')}>
                            <div className={clsx('flex', 'flex-col', 'justify-center', 'w-[160px]', 'min-w-[160px]')}>
                                <a href="https://www.re-gista.co.jp/" target="_blank">
                                    <img
                                        className={clsx('my-4')}
                                        src="/src/assets/image/company_logo/regista.png"
                                        alt="レジスタ合同会社"
                                    />
                                </a>
                                <p className={clsx('text-xs', 'text-center')}>
                                    <a
                                        href="https://www.re-gista.co.jp/"
                                        target="_blank"
                                        className={clsx('underline', 'text-kimar-primary')}>
                                        レジスタ合同会社
                                    </a>
                                    <br />
                                    代表社員 小島様
                                </p>
                            </div>
                            <div className={clsx('flex', 'flex-col', 'grow', 'mb-3')}>
                                <h3 className={clsx('text-kimar-primary', 'text-2xl', 'mb-4')}>
                                    『シンプルで簡単な操作で、
                                    <strong className={clsx('border-b-[3px]', 'border-kimar-primary')}>スムーズに運用</strong>
                                    できました！』
                                </h3>
                                {interview2Visible ? (
                                    <div>
                                        <h4 className={clsx('font-bold', 'mb-2')}>
                                            <span className={clsx('leading-3')}>——</span>会社概要と事業の特徴を教えてください
                                        </h4>
                                        <p className={clsx('mb-4', 'text-sm')}>
                                            レジスタ合同会社は、不動産の賃貸・売買仲介を中心に、リフォーム工事等も行う不動産会社です。また、他社との差別化を図るために、盗聴器・盗撮器の調査という少し変わったサービスも提供しています。
                                        </p>
                                        <h4 className={clsx('font-bold', 'mb-2')}>
                                            <span className={clsx('leading-3')}>——</span>
                                            <span className={clsx('text-kimar-primary')}>キマール</span>
                                            導入前の課題を教えてください
                                        </h4>
                                        <p className={clsx('mb-4', 'text-sm')}>
                                            主な課題は、物件情報や顧客情報の管理、業務のＩＴ化です。
                                            実は過去に、不動産業者に特化した業務支援システムの開発に挑戦したことがあります。しかし、実現には至らず、既成システムの導入に方針転換しました。
                                            自らがシステム開発に着手したからこそ、
                                            <b>
                                                不動産業者のために作られたシステム「
                                                <span className={clsx('text-kimar-primary')}>キマール</span>
                                                」に魅力を感じ
                                            </b>
                                            、導入に至りました。
                                        </p>
                                        <h4 className={clsx('font-bold', 'mb-2')}>
                                            <span className={clsx('leading-3')}>——</span>
                                            <span className={clsx('text-kimar-primary')}>キマール</span>
                                            の導入はスムーズでしたか？
                                        </h4>
                                        <p className={clsx('mb-4', 'text-sm')}>
                                            まだまだアナログな慣習が残っている業界なので、現場から反発の声はありました。
                                            <br />
                                            ただ
                                            <span className={clsx('text-kimar-primary')}>キマール</span>
                                            はとてもシンプルなシステムで、PCが苦手な社員でも簡単に操作できるので、
                                            <b>導入後はスムーズに運用</b>できました。
                                        </p>
                                        <h4 className={clsx('font-bold', 'mb-2')}>
                                            <span className={clsx('leading-3')}>——</span>実際に
                                            <span className={clsx('text-kimar-primary')}>キマール</span>
                                            を導入し、業務にはどのような変化がありましたか？
                                        </h4>
                                        <p className={clsx('mb-4', 'text-sm')}>
                                            業務効率が改善されました。
                                            <br />
                                            1点目は、物件資料の管理です。以前は物件資料の格納先が把握しきれておらず、大量のフォルダから資料を探すという手間が発生してたのですが、
                                            <span className={clsx('text-kimar-primary')}>キマール</span>
                                            導入後は、<b>物件情報にアクセスするだけですべての物件資料を確認できる</b>
                                            ようになりました。
                                            <br />
                                            2点目は、顧客への物件紹介です。
                                            <br />
                                            <b>少ない動作で簡単に一斉配信</b>
                                            することができる上に、紹介先のメール開封・未開封や資料の閲覧回数まで確認できるので、紹介後の追客業務にも役立っています。
                                        </p>
                                        <h4 className={clsx('font-bold', 'mb-2')}>
                                            <span className={clsx('leading-3')}>——</span>
                                            最後に、今後弊社に期待していること、改善してほしいことがあれば教えてください
                                        </h4>
                                        <p className={clsx('mb-4', 'text-sm')}>
                                            機能的な要望としては、概要書の自動作成機能<small>(※)</small>があったら嬉しいです。
                                        </p>
                                        <h4 className={clsx('font-bold', 'mb-2')}>
                                            <span className={clsx('leading-3')}>——</span>
                                            インタビューにお答えいただき、ありがとうございました
                                        </h4>
                                        <p className={clsx('mb-4', 'text-sm')}>
                                            <small>※ 2022年11月30日に物件概要書作成機能をリリースしました</small>
                                        </p>
                                    </div>
                                ) : (
                                    <div>
                                        <h4 className={clsx('font-bold', 'mb-2')}>
                                            <span className={clsx('leading-3')}>——</span>実際に
                                            <span className={clsx('text-kimar-primary')}>キマール</span>
                                            を導入し、業務にはどのような変化がありましたか？
                                        </h4>
                                        <p className={clsx('mb-4', 'text-sm')}>
                                            業務効率が改善されました。
                                            <br />
                                            1点目は、物件資料の管理です。以前は物件資料の格納先が把握しきれておらず、大量のフォルダから資料を探すという手間が発生してたのですが、
                                            <span className={clsx('text-kimar-primary')}>キマール</span>
                                            導入後は、<b>物件情報にアクセスするだけですべての物件資料を確認できる</b>
                                            ようになりました。
                                            <br />
                                            2点目は、顧客への物件紹介です。
                                            <br />
                                            <b>少ない動作で簡単に一斉配信</b>
                                            することができる上に、紹介先のメール開封・未開封や資料の閲覧回数まで確認できるので、紹介後の追客業務にも役立っています。
                                        </p>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div>
                            <CButton
                                className={clsx('rounded-full', 'bg-kimar-primary', 'text-white', 'px-3', 'py-1')}
                                onClick={() => setInterview2Visible((oldValue) => !oldValue)}>
                                {interview2Visible ? (
                                    <AnchorLink offset="64" href="#interview2" className={clsx('flex', 'flex-row', 'gap-1')}>
                                        <span>{t('LP.インタビューの内容を閉じる')}</span>
                                        <img src="/src/assets/image/page/lp/arrow_up.svg" alt="インタビューの内容を閉じる" />
                                    </AnchorLink>
                                ) : (
                                    <AnchorLink offset="64" href="#interview2" className={clsx('flex', 'flex-row', 'gap-1')}>
                                        <span>
                                            <Trans i18nKey="LP.インタビューの内容を詳しく見る">
                                                <span className={clsx('inline-block')}>インタビューの内容を</span>
                                                <span className={clsx('inline-block')}>詳しく見る</span>
                                            </Trans>
                                        </span>
                                        <img
                                            src="/src/assets/image/page/lp/arrow_down.svg"
                                            alt="インタビューの内容を詳しく見る"
                                        />
                                    </AnchorLink>
                                )}
                            </CButton>
                        </div>
                    </article>
                    <article
                        id="interview1"
                        className={clsx(
                            'bg-white',
                            'rounded-3xl',
                            'flex',
                            'flex-col',
                            'items-center',
                            'p-6',
                            'max-w-[968px]',
                            'mx-auto',
                        )}>
                        <div
                            className={clsx(
                                'flex',
                                'flex-wrap',
                                'sm:flex-nowrap',
                                'flex-row-reverse',
                                'justify-center',
                                'gap-4',
                            )}>
                            <div className={clsx('flex', 'flex-col', 'justify-center', 'w-[160px]', 'min-w-[160px]')}>
                                <img src="/src/assets/image/company_logo/factor_nine.jpg" alt="株式会社ファクター・ナイン" />
                                <p className={clsx('text-xs', 'text-center')}>
                                    株式会社ファクター・ナイン
                                    <br />
                                    東京支店支店長 木村様
                                </p>
                            </div>
                            <div className={clsx('flex', 'flex-col', 'grow', 'mb-3')}>
                                <h3 className={clsx('text-kimar-primary', 'text-2xl', 'mb-4')}>
                                    『
                                    <strong className={clsx('border-b-[3px]', 'border-kimar-primary')}>使いやすいシステム</strong>
                                    だなと感じています！』
                                </h3>
                                {interview1Visible ? (
                                    <div>
                                        <h4 className={clsx('font-bold', 'mb-2')}>
                                            <span className={clsx('leading-3')}>——</span>会社概要と事業の特徴を教えてください
                                        </h4>
                                        <p className={clsx('mb-4', 'text-sm')}>
                                            株式会社ファクター・ナインは北海道・札幌に本社を構え、主に賃貸用デザイナーズマンションの企画販売事業を展開している会社です。また、他にもグループ会社で建築・管理・インフラ関連の事業会社もあり、札幌市内を中心に不動産投資におけるワンストップサービスを行っております。
                                            <br />
                                            弊社では主にRC造の洗練されたデザイン・設備を備えた賃貸用デザイナーズマンション企画・開発を行っており、全国の投資家オーナー様に購入・ご好評を頂いております。
                                            <br />
                                            我が東京支店では上記物件の販売に加え、不動産売買と仲介を中心に行っています。
                                        </p>
                                        <h4 className={clsx('font-bold', 'mb-2')}>
                                            <span className={clsx('leading-3')}>——</span>
                                            <span className={clsx('text-kimar-primary')}>キマール</span>
                                            導入前の課題を教えてください
                                        </h4>
                                        <p className={clsx('mb-4', 'text-sm')}>
                                            3年前に支店を立ち上げた際には、情報や業務を管理するような機能が無く、取りまとめられるようなものはないかなと考えていました。
                                            当時は私ひとりでしたので、電話やメール・その他事務作業等の全てを対応する中でどこかの時間を削れないものかと思っておりました。
                                            弊社の代表取締役である高橋もかねてからDX化を推進している中、2021年5月不動産テック協会にも加盟させて頂いた縁もあってとあるイベントで「
                                            <span className={clsx('text-kimar-primary')}>キマール</span>
                                            」の存在を知りました。業者さんとのやり取りが非常に多い業界ですので、そういった部分を
                                            <b>簡素化して生産性のある時間を作っていける</b>ような「
                                            <span className={clsx('text-kimar-primary')}>キマール</span>
                                            」のサービスに魅力を感じ導入に至りました。
                                        </p>
                                        <h4 className={clsx('font-bold', 'mb-2')}>
                                            <span className={clsx('leading-3')}>——</span>
                                            <span className={clsx('text-kimar-primary')}>キマール</span>
                                            の導入はスムーズでしたか？
                                        </h4>
                                        <p className={clsx('mb-4', 'text-sm')}>
                                            まだまだDXアレルギーを感じる社員がいるのが実情で、導入に至っては反対や疑問の声も多数ありました。
                                            <br />
                                            ですが、「
                                            <span className={clsx('text-kimar-primary')}>キマール</span>
                                            」は比較的シンプルな作り込みで、前述のような社員においても
                                            <b>使いやすいシステム</b>
                                            だなと感じていて、導入後は思いのほかスムーズな運用が出来ております。
                                        </p>
                                        <h4 className={clsx('font-bold', 'mb-2')}>
                                            <span className={clsx('leading-3')}>——</span>実際に
                                            <span className={clsx('text-kimar-primary')}>キマール</span>
                                            を導入し、業務にはどのような変化がありましたか？
                                        </h4>
                                        <p className={clsx('mb-4', 'text-sm')}>
                                            一番大きいのはメール作成の作業時間や、電話のやり取りの時間がかなり短縮されたことです。
                                            <br />「<span className={clsx('text-kimar-primary')}>キマール</span>
                                            」を使って案件化し、<b>ご紹介して成約に至った</b>
                                            事例も出てきましたし、案件のやり取りの際に出てくる重たいデータや数多いデータも容量制限がないため
                                            <b>スムーズに出来る</b>という点が非常に良かったなと感じています。
                                            <br />
                                            何度もメールの文面を打ち込んだり、この人にはこの資料を送る・送らない、資料を貼り付けて重い資料だと弾かれてしまう・・・といったような
                                            <b>ストレスが全く無くなった</b>
                                            ことで、より多く紹介の幅を広げ、成約への可能性を高める方向に目を向けられるようになりました。
                                        </p>
                                        <h4 className={clsx('font-bold', 'mb-2')}>
                                            <span className={clsx('leading-3')}>——</span>
                                            最後に、今後弊社に期待していること、改善してほしいことがあれば教えてください
                                        </h4>
                                        <p className={clsx('mb-4', 'text-sm')}>
                                            機能の要望としては、物件概要書の自動作成機能<small>(※)</small>
                                            があったらいいなという点と、今後さらにキマールマーケットが活発化し、取引が増え、不動産業界はもとより不動産テック業界におきましても今後より発展していくことを期待しています。
                                        </p>
                                        <h4 className={clsx('font-bold', 'mb-2')}>
                                            <span className={clsx('leading-3')}>——</span>
                                            インタビューにお答えいただき、ありがとうございました
                                        </h4>
                                        <p className={clsx('mb-4', 'text-sm')}>
                                            <small>※ 2022年11月30日に物件概要書作成機能をリリースしました</small>
                                        </p>
                                    </div>
                                ) : (
                                    <div>
                                        <h4 className={clsx('font-bold', 'mb-2')}>
                                            <span className={clsx('leading-3')}>——</span>実際に
                                            <span className={clsx('text-kimar-primary')}>キマール</span>
                                            を導入し、業務にはどのような変化がありましたか？
                                        </h4>
                                        <p className={clsx('mb-4', 'text-sm')}>
                                            一番大きいのはメール作成の作業時間や、電話のやり取りの時間がかなり短縮されたことです。
                                            <br />「<span className={clsx('text-kimar-primary')}>キマール</span>
                                            」を使って案件化し、<b>ご紹介して成約に至った</b>
                                            事例も出てきましたし、案件のやり取りの際に出てくる重たいデータや数多いデータも容量制限がないため
                                            <b>スムーズに出来る</b>という点が非常に良かったなと感じています。
                                            <br />
                                            何度もメールの文面を打ち込んだり、この人にはこの資料を送る・送らない、資料を貼り付けて重い資料だと弾かれてしまう・・・といったような
                                            <b>ストレスが全く無くなった</b>
                                            ことで、より多く紹介の幅を広げ、成約への可能性を高める方向に目を向けられるようになりました。
                                        </p>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div>
                            <CButton
                                className={clsx('rounded-full', 'bg-kimar-primary', 'text-white', 'px-3', 'py-1')}
                                onClick={() => setInterview1Visible((oldValue) => !oldValue)}>
                                {interview2Visible ? (
                                    <AnchorLink offset="64" href="#interview1" className={clsx('flex', 'flex-row', 'gap-1')}>
                                        <span>{t('LP.インタビューの内容を閉じる')}</span>
                                        <img src="/src/assets/image/page/lp/arrow_up.svg" alt="インタビューの内容を閉じる" />
                                    </AnchorLink>
                                ) : (
                                    <AnchorLink offset="64" href="#interview1" className={clsx('flex', 'flex-row', 'gap-1')}>
                                        <span>
                                            <Trans i18nKey="LP.インタビューの内容を詳しく見る">
                                                <span className={clsx('inline-block')}>インタビューの内容を</span>
                                                <span className={clsx('inline-block')}>詳しく見る</span>
                                            </Trans>
                                        </span>
                                        <img
                                            src="/src/assets/image/page/lp/arrow_down.svg"
                                            alt="インタビューの内容を詳しく見る"
                                        />
                                    </AnchorLink>
                                )}
                            </CButton>
                        </div>
                    </article>
                    <AnchorLink
                        href="#download"
                        className={clsx(
                            'mx-auto',
                            'w-full',
                            'max-w-[768px]',
                            'rounded-full',
                            'bg-kimar-accent',
                            'font-bold',
                            'text-xl',
                            'flex',
                            'justify-center',
                            'py-5',
                            'my-5',
                            'outline',
                            'outline-kimar-accent',
                            'hover:bg-white',
                            'hover:outline-2',
                            'hover:outline-kimar-accent',
                            'transition',
                        )}
                        offset="64">
                        {t('LP.資料ダウンロードはこちら')}
                    </AnchorLink>
                </div>
                {/* よくあるご質問 */}
                <div id="faq" className={clsx('bg-gray-100', 'p-8', 'flex', 'flex-col', 'justify-center', 'items-center')}>
                    <div className={clsx('text-3xl', 'font-bold', 'w-full', 'mb-4')}>
                        <img className={clsx('mx-auto', 'p-4')} src="/src/assets/image/icon/check.svg" alt="check" />
                        <div className={clsx('flex', 'justify-center')}>
                            <span>{t('LP.よくあるご質問')}</span>
                        </div>
                    </div>
                    {isLoadingFaqList ? (
                        <CMessage info>{t('Message.読み込み中です…')}</CMessage>
                    ) : (
                        <div className={clsx('max-w-[768px]', 'w-full')}>
                            <ul className={clsx('mx-auto', 'table', 'border-collapse', 'w-full')}>
                                {(faqList?.list || []).map((content) => (
                                    <li key={content.uuid} className={clsx('border-y-2', 'table-row', 'w-full')}>
                                        <CFaqListItem faq={content} />
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                    <CLinkButton
                        to="/faq"
                        className={clsx(
                            'mx-auto',
                            'w-full',
                            'max-w-xl',
                            'rounded-full',
                            'text-white',
                            'bg-kimar-primary',
                            'font-bold',
                            'text-xl',
                            'flex',
                            'justify-center',
                            'py-5',
                            'my-5',
                            'outline',
                            'outline-kimar-primary',
                            'hover:bg-white',
                            'hover:text-kimar-primary',
                            'hover:outline-2',
                            'hover:outline-kimar-primary',
                            'transition',
                        )}>
                        {t('LP.全質問を見る')}
                    </CLinkButton>
                </div>
                <div id="download" className={clsx('bg-white', 'py-10')}>
                    <div className={clsx('text-3xl', 'font-bold', 'w-full', 'mb-8')}>
                        <img className={clsx('mx-auto', 'p-4')} src="/src/assets/image/icon/check.svg" alt="check" />
                        <div className={clsx('flex', 'flex-wrap', 'justify-center')}>
                            <span className={clsx('inline-block')}>{t('LP.かんたん入力で')}</span>
                            <span>{t('LP.今すぐ資料ダウンロード')}</span>
                        </div>
                    </div>
                    <div className={clsx('flex', 'flex-col', 'items-center', 'gap-4', 'max-w-[768px]', 'mx-auto')}>
                        <CDownloadForm />
                    </div>
                </div>
            </div>
        </>
    )
}

export type LPProps = {}

export const LP: VFC<LPProps> = (props) => {
    return <LPView {...props} />
}
